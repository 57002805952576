var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Keresés","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cafeteriaStatementDataCollection,"search":_vm.search,"sort-by":['year'],"loading":_vm.loadingTable,"footer-props":{
          'items-per-page-options': [100],
        }},on:{"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',[_c('v-select',{attrs:{"items":[].concat( _vm.yearCollection ),"item-text":"year","item-value":"id"},on:{"change":_vm.fetchCafeteriaStatementData},model:{value:(_vm.filterData.year),callback:function ($$v) {_vm.$set(_vm.filterData, "year", $$v)},expression:"filterData.year"}})],1),_c('th',[_c('v-select',{attrs:{"items":[
                    { id: null, fullName: 'Összes munkatárs' } ].concat( _vm.employeeCollection ),"item-text":"fullName","item-value":"id","disabled":!_vm.permissionCan('delete')},on:{"change":_vm.fetchCafeteriaStatementData},model:{value:(_vm.filterData.employee_id),callback:function ($$v) {_vm.$set(_vm.filterData, "employee_id", $$v)},expression:"filterData.employee_id"}})],1),_c('th',[(
                    _vm.cafeteriaStatementDataToExcelCollection.length != 0 &&
                    _vm.permissionCan('delete')
                  )?_c('export-excel',{staticClass:"btn",attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"worksheet":"cafeteria","name":_vm.exportName}},[_c('v-btn',{staticStyle:{"width":"100%!import"},attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.excel_export"))+" ")])],1):_vm._e()],1)])])]}},{key:"item.cafeteria_statement_data",fn:function(ref){
                  var item = ref.item;
return [(item.cafeteria_statement_data)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[(_vm.canCreate)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleNew}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.$t("MENU.CAFETERIASTATEMENTDATA"))+" ")]):_vm._e(),_c('v-spacer'),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(_vm.permissionCan('delete') && item.id)?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('CafeteriaStatementDataModalForm',{attrs:{"modalData":_vm.modalData,"userGroups":_vm.userGroupCollection,"permissions":_vm.permissions,"statuses":_vm.statuses,"employee":_vm.employee},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }