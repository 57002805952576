<template>
  <v-snackbar
    v-model="snackbarInfo.active"
    auto-height
    :color="snackbarInfo.color"
    :timeout="snackbarInfo.timeout"
    :top="true"
  >
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>{{ snackbarInfo.icon }}</v-icon>
      <v-layout column>
        <div>
          <strong>{{ Info }}</strong>
        </div>
        <div style="font-size: 1.25rem">
          <b>{{ snackbarInfo.text }}</b>
        </div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
// import i18n from "@/i18n/index.js";
import i18n from "@/core/plugins/vue-i18n";

export const INITIAL_SNACKBAR_INFO = {
  active: false,
  text: i18n.t("SNACK_BAR.saved"),
  timeout: 2000,
  icon: "check_circle",
  color: "green",
};

export default {
  name: "SnackBarInfoComponent",
  props: ["snackbarInfo"],
};
</script>
