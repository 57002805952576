<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Keresés"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="cafeteriaStatementDataCollection"
          :search="search"
          :sort-by="['year']"
          @click:row="handleClickItem"
          :loading="loadingTable"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th>
                  <v-select
                    v-model="filterData.year"
                    :items="[...yearCollection]"
                    item-text="year"
                    item-value="id"
                    @change="fetchCafeteriaStatementData"
                  ></v-select>
                </th>
                <th>
                  <v-select
                    v-model="filterData.employee_id"
                    :items="[
                      { id: null, fullName: 'Összes munkatárs' },
                      ...employeeCollection,
                    ]"
                    item-text="fullName"
                    item-value="id"
                    :disabled="!permissionCan('delete')"
                    @change="fetchCafeteriaStatementData"
                  ></v-select>
                </th>
                <th>
                  <export-excel
                    v-if="
                      cafeteriaStatementDataToExcelCollection.length != 0 &&
                      permissionCan('delete')
                    "
                    class="btn"
                    :data="json_data"
                    :fields="json_fields"
                    worksheet="cafeteria"
                    :name="exportName"
                  >
                    <v-btn color="primary" dark style="width: 100%!import">
                      {{ $t("FORMS.excel_export") }}
                    </v-btn>
                  </export-excel>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item.cafeteria_statement_data="{ item }">
            <v-icon v-if="item.cafeteria_statement_data" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-btn
                v-if="canCreate"
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="handleNew"
              >
                {{ $t("MENU.NEW") }} {{ $t("MENU.CAFETERIASTATEMENTDATA") }}
              </v-btn>

              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              small
              @click="deleteItem(item)"
              v-if="permissionCan('delete') && item.id"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>

        <CafeteriaStatementDataModalForm
          :modalData="modalData"
          :userGroups="userGroupCollection"
          :permissions="permissions"
          :statuses="statuses"
          :employee="employee"
          @closeModalForm="handleCloseModalForm"
          @saveModalForm="handleSaveModalForm"
          @new="handleNew"
        ></CafeteriaStatementDataModalForm>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CafeteriaStatementDataModalForm, {
  initialFormData,
} from "./CafeteriaStatementDataModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
export const ENDPOINT = "cafeteria/admin/cafeteriaStatementData/";
export const YEARENDPOINT = "cafeteria/admin/cafeteriaStatement/getByYear/";
import ApiService from "@/core/services/api.service";

export const PERMISSION_TO = "cafeteria.cafeteriaStatementData.";

const INITIAL_FILTER_DATA = {
  year: new Date().getFullYear(),
  employee_id: null,
};

export default {
  name: "CafeteriaStatementDatas",
  components: {
    CafeteriaStatementDataModalForm,
    DeleteModalDialog,
  },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "cafeteriaStatements",
      routePath: "/humanResource/cafeteria/cafeteriaStatementDatas/",
      permissionTo: PERMISSION_TO,

      cardTitle: this.$t("MENU.CAFETERIA_STATEMENTS"),
      search: "",
      permissions: JSON.parse(localStorage.permissions),

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),
      dialogDelete: false,
      loadingTable: false,
      endPoint: ENDPOINT,

      filterData: Object.assign({}, INITIAL_FILTER_DATA),
      cafeteriaStatementsCollection: [],
      cafeteriaStatementDataCollection: [],
      employee: null,
      canCreate: null,
      exportName: "cafeteria.xls",
      // json_fields: {
      //   Létrehozva: "created_at",
      //   Email: "email",
      //   Telefonszám: "phone",
      //   Vállalat: "company_name",
      //   Üzenet: "message",
      //   Nyelv: "locale",
      //   "Távoli IP cím": "remote_ip",
      //   "User agent": "user_agent",
      //   "Űrlap ID": "contact_form_id",
      //   Név: "name",
      // },
      // json_data: [
      //   {
      //     id: 252,
      //     created_at: "2022-05-27 10:52:31",
      //     email: "onodylaszlo1978@gmail.com",
      //     phone: null,
      //     company_name: null,
      //     message: "TESSSSSZZZT!!!!!!",
      //     custom_fields: null,
      //     locale: "hu",
      //     remote_ip: "88.132.248.65",
      //     user_agent:
      //       "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.5005.61 Safari/537.36",
      //     contact_form_id: 1,
      //     name: "Onody Laszlo",
      //   },
      //   {
      //     id: 253,
      //     created_at: "2022-07-14 10:14:07",
      //     email: "robert.viski@hrenko.hu",
      //     phone: null,
      //     company_name: null,
      //     message: "Teszt Kapcsolat",
      //     custom_fields: null,
      //     locale: "hu",
      //     remote_ip: "94.21.26.121",
      //     user_agent:
      //       "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36",
      //     contact_form_id: 1,
      //     name: "Viski Róbert",
      //   },
      //   {
      //     id: 254,
      //     created_at: "2022-07-27 08:25:00",
      //     email: "robert.viski@hrenko.hu",
      //     phone: null,
      //     company_name: null,
      //     message: "Teszt",
      //     custom_fields: null,
      //     locale: "hu",
      //     remote_ip: "85.67.47.66",
      //     user_agent:
      //       "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36",
      //     contact_form_id: 1,
      //     name: "Viski Róbert",
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters([
      "statuses",
      "employeeCollection",
      "yearCollection",
      "currentUser",
      "getEmployeeByID",
    ]),
    json_fields() {
      let obj = {};
      this.headers
        .filter(
          (header) =>
            header.value != "actions" &&
            header.value != "cafeteria_statement_data"
        )
        .forEach((item) => {
          // let obj = {};
          obj[item.text] = item.value;
        });

      return obj;
    },

    json_data() {
      return this.cafeteriaStatementDataCollection
        .filter((cafeteria) => cafeteria.cafeteria_statement_data)
        .map((item) => {
          let obj = {};
          Object.keys(this.json_fields).forEach((key) => {
            obj[this.json_fields[key]] = item[this.json_fields[key]];
          });

          return obj;
        });
    },

    cafeteriaStatementDataToExcelCollection() {
      return this.cafeteriaStatementDataCollection.filter(
        (item) => item.cafeteria_statement_data
      );
    },
    headers() {
      let headerQuestions = [];
      let employeeWithCafeteria = this.cafeteriaStatementDataCollection.find(
        (item) => item.cafeteria_statement_data
      );
      if (employeeWithCafeteria) {
        for (
          let i = 0;
          i <
          employeeWithCafeteria.cafeteria_statement_data.custom_fields.length;
          i++
        ) {
          let header = {
            text: i + 1 + ". válasz",
            value: i + 1 + "_value",
          };
          headerQuestions.push(header);
        }
      }

      let headers = [
        {
          text: "Munkaév",
          value: "year",
          width: "120px",
        },
        {
          text: "Munkatárs neve",
          value: "fullName",
        },

        ...headerQuestions,

        {
          text: "Cafeteria",
          value: "cafeteria_statement_data",
          width: "180px",
        },
        {
          text: "Műveletek",
          width: "120px",
          value: "actions",
          sortable: false,
        },
      ];
      return headers;
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        this.dialogHours = false;
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["fetchEmployee", "fetchYear"]),

    fetchCafeteriaStatementData() {
      return new Promise((resolve) => {
        // let appLocale = i18nService.getActiveLanguage();
        ApiService.post(
          "cafeteria/admin/cafeteriaStatementData/filter",
          this.filterData
        )
          .then(({ data }) => {
            this.cafeteriaStatementDataCollection = data.map((item) => {
              item.fullName = item.name;

              if (item.cafeteria_statement_data) {
                item.id = item.cafeteria_statement_data.id;
                if (item.cafeteria_statement_data.cafeteria_statement) {
                  item.year =
                    item.cafeteria_statement_data.cafeteria_statement.year;
                }

                for (
                  let i = 0;
                  i < item.cafeteria_statement_data.custom_fields.length;
                  i++
                ) {
                  if (
                    item.cafeteria_statement_data.custom_fields[i].form_input ==
                    "radio"
                  ) {
                    item[i + 1 + "_value"] = item.cafeteria_statement_data
                      .custom_fields[i].value
                      ? "Részesülök"
                      : "Nem részesülök";
                  } else {
                    item[i + 1 + "_value"] =
                      item.cafeteria_statement_data.custom_fields[i].value;
                  }
                }
              }

              return item;
            });
            resolve();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    fetchModel() {
      this.fetchEmployee();
      this.fetchYear();
      this.setCanCreate();
      return this.fetchCafeteriaStatementData();
    },

    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    setCanCreate() {
      let employee_id = this.currentUser.employee_id;
      if (!this.permissionCan("create") || !employee_id) {
        this.canCreate = false;
        return null;
      }

      let currentYear = new Date().getFullYear();
      let filterData = {
        year: currentYear,
        employee_id: employee_id,
      };

      ApiService.post(
        "cafeteria/admin/cafeteriaStatementData/filter",
        filterData
      )
        .then(({ data }) => {
          let cafeteriaStatementDataCollection = data.map((item) => {
            item.fullName = item.name;

            if (item.cafeteria_statement_data) {
              item.id = item.cafeteria_statement_data.id;
              if (item.cafeteria_statement_data.cafeteria_statement) {
                item.year =
                  item.cafeteria_statement_data.cafeteria_statement.year;
              }
            }

            return item;
          });

          this.canCreate = !cafeteriaStatementDataCollection.find(
            (item) => item.year == currentYear
          );
          return null;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          return null;
        });
    },
  },
  mounted() {
    this.loadingTable = true;
    setTimeout(() => {
      if (this.permissionCan("delete")) {
        this.filterData = {
          year: new Date().getFullYear(),
          employee_id: null,
        };
      } else {
        this.filterData = {
          year: new Date().getFullYear(),
          employee_id: this.currentUser.employee_id,
          // employee_id: 3,
        };
      }
      this.fetchModel().then(() => {
        this.loadingTable = false;
        if (this.currentUser.employee_id) {
          this.employee = this.getEmployeeByID(this.currentUser.employee_id);
        }

        // this.setCanCreate();
      });
    }, 0);
  },
};
</script>
