<template>
  <v-dialog
    max-width="500"
    v-model="dialog"
    v-if="text"
    :retain-focus="false"
    scrollable
  >
    <!-- <template v-slot:activator="{ props }">
    <v-btn icon v-bind="props" color="primary"  @click="dialog = true">
      <v-icon color="grey-lighten-1">

        mdi-information
      </v-icon>
    </v-btn>
  </template>
   -->
    <!-- <template v-slot:activator="{ props }">
      <b-iconstack
        class="ml-1"
        font-scale="1"
        animation="cylon"
        @click="dialog = true"
      >
        <b-icon
          stacked
          icon="info-circle"
          animation="throb"
          variant="primary"
          scale="0.1"
        ></b-icon>
      </b-iconstack>
    </template> -->
    <template v-slot:activator="{ props }">
      <b-iconstack class="ml-1" @click="dialog = true">
        <b-icon
          stacked
          icon="info-circle"
          animation="throb"
          variant="primary"
          scale="1.2"
        ></b-icon>
      </b-iconstack>
    </template>

    <v-card>
      <v-card-title v-html="title"> </v-card-title>

      <v-card-text v-html="text"> </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text elevation="2" @click="dialog = false">
          {{ $t("FORMS.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TooltipModal",
  props: ["title", "text"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
