<template>
  <v-col v-if="showAIBlock">
    <v-card class="mx-auto mb-5">
      <v-card-text>
        <v-form v-model="AIFormValid" ref="AIFormValid">
          <div class="mb-5 text-h5 text--primary text-center">
            <!-- {{ $t("PERMISSIONS.system_synergyfox_assistant") }} -->
            <img src="/foxAI.svg" alt="SynergyFox AI logo" class="foxAI_logo" />
          </div>
          <v-select
            :items="requestTypes"
            v-model="formModel.selectedRequest"
            :label="$t('FORM_INPUT_NAMES.select_type') + '*'"
            item-text="title"
            item-value="type"
            return-object
            :rules="requiredRules"
            @change="changedSelectedRequest"
          >
          </v-select>
          <v-text-field
            v-if="showInputField('subject')"
            v-model="formModel.subject"
            :label="subjectLabel + '*'"
            :rules="requiredRules"
          ></v-text-field>

          <v-combobox
            v-if="showInputField('keywords')"
            v-model="formModel.keywords"
            :label="$t('FORM_INPUT_NAMES.keywords')"
            multiple
            clearable
            chips
          >
            <template #selection="{ item }">
              <v-chip
                close
                color="bg-gray-100"
                @click:close="deleteChipComboBox(item, formModel.keywords)"
                >{{ item }}</v-chip
              >
            </template>
          </v-combobox>

          <v-textarea
            v-if="showInputField('source_text')"
            v-model="formModel.source_text"
            :label="$t('FORM_INPUT_NAMES.source_text') + '*'"
            :rules="[requiredRules, rules.maxLength(200, formModel)].flat()"
          ></v-textarea>

          <v-row>
            <!-- <v-col cols="12" md="4" v-if="showInputField('length')">
              <v-text-field
                v-model="formModel.length"
                :label="$t('FORM_INPUT_NAMES.char_length')"
                :rules="requiredRules"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="4">
              <v-text-field
                v-if="showInputField('number')"
                v-model="formModel.number"
                :label="$t('FORM_INPUT_NAMES.number')"
                :rules="[rules.min(1)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-slider
                v-if="showInputField('temperature')"
                v-model="formModel.temperature"
                :label="
                  $t('FORM_INPUT_NAMES.temperature') +
                  ': ' +
                  formModel.temperature
                "
                :min="0.5"
                :max="1.2"
                :step="0.1"
                thumb-label
              ></v-slider>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn
          v-if="formModel.selectedRequest"
          @click="handleTokenizer()"
          color="primary"
          text
        >
          {{ $t("FORM_INPUT_NAMES.tokenizer") }}
        </v-btn> -->
        <v-spacer></v-spacer>
        <v-btn
          v-if="formModel.selectedRequest"
          color="primary"
          text
          @click="handleGenerateAnswer()"
        >
          {{ $t("FORM_INPUT_NAMES.generate_response") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="showAnswer && choices.all.length > 0" class="mt-3 pt-3">
      {{ $t("FORM_INPUT_NAMES.response_text") }}
    </div>
    <!-- :label="$t('FORM_INPUT_NAMES.response_text')" -->
    <v-textarea
      v-if="showAnswer && choices.all.length > 0"
      v-model="choices.all[choices.selected].text"
      class="mb-2"
    ></v-textarea>
    <v-row
      align="center"
      justify="center"
      v-if="showAnswer && choices.all.length > 0"
      class="mb-0 pb-0"
    >
      <v-col cols="auto" class="my-0 py-0">
        {{ choices.all.length }}/{{ choices.selected + 1 }}
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      v-if="showAnswer && choices.all.length > 0"
      class="mt-0 pt-0"
    >
      <v-col cols="auto">
        <v-btn
          v-if="choices.all.length > 1"
          density="comfortable"
          icon="mdi-arrow-left-bold-circle-outline"
          :disabled="choices.all.length > 1 && choices.selected < 1"
          @click="choices.selected--"
          ><v-icon>mdi-arrow-left-bold-circle-outline</v-icon></v-btn
        >
      </v-col>

      <v-col cols="auto">
        <v-btn
          density="default"
          :disabled="choices.all[choices.selected].text.length < 1"
          @click="copyText"
          ><v-icon>mdi-content-copy </v-icon></v-btn
        >
      </v-col>

      <v-col cols="auto">
        <v-btn
          v-if="choices.all.length > 1"
          density="compact"
          icon="mdi-arrow-right-bold-circle-outline"
          :disabled="choices.all.length - 1 <= choices.selected"
          @click="choices.selected++"
          ><v-icon>mdi-arrow-right-bold-circle-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-col>
</template>

<script>
import openAIconfig from "@/core/config/openai-api.config.json";
import GPT3Tokenizer from "gpt3-tokenizer";
import i18n from "@/core/plugins/vue-i18n";

const INITIAL_FORM_MODEL = {
  selectedRequest: null,
  keywords: null,
  source_text: null,
  subject: null,
  number: 1,
  length: " ",
  response_text: null,
  temperature: 0.8,
};

const INITIAL_CHOICES = {
  all: [],
  selected: 0,
};

export default {
  name: "AIComponent",
  props: ["showAIBlock", "selectedLocale"],
  data() {
    return {
      showAll: false,
      showAnswer: false,
      formModel: INITIAL_FORM_MODEL,
      AIFormValid: false,
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      rules: {
        min(minNum) {
          return (v) =>
            (v || "") >= minNum ||
            i18n.t("ERRORS.minimum_value", { minNum: minNum });
        },

        maxLength(maxLen, formModel) {
          if (
            formModel.selectedRequest &&
            formModel.selectedRequest.type != "seo_title" &&
            formModel.selectedRequest.type != "seo_description"
          ) {
            return true;
          }
          return (v) =>
            (v && v.length < maxLen) ||
            i18n.t("ERRORS.maximum_length", { maxLen: maxLen });
        },
      },
      generatedPrompt: null,

      choices: INITIAL_CHOICES,

      loader: false,
    };
  },

  computed: {
    computedCols() {
      if (this.showAIBlock) return 9;

      return 12;
    },

    requestTypes() {
      if (!this.selectedLocale || !this.selectedLocale.lang) return [];

      return openAIconfig.selects.map((item) => {
        item.title = "Not translated!";
        if (item[this.selectedLocale.lang]) {
          item.title = item[this.selectedLocale.lang].title;
        } else {
          if (item[process.env.VUE_APP_I18N_LOCALE]) {
            item.title = item[process.env.VUE_APP_I18N_LOCALE].title;
          } else if (item["en"]) item.title = item["en"].title;
        }
        return item;
      });
    },

    subjectLabel() {
      if (
        this.formModel.selectedRequest &&
        this.formModel.selectedRequest.type == "custom"
      ) {
        return this.$t("FORM_INPUT_NAMES.subject_label");
      }

      return this.$t("FORM_INPUT_NAMES.subject");
    },
  },

  methods: {
    AI_API_CALL() {
      this.loader = true;
      // this.choices.all = [
      //   {
      //     text: "Az Aranymetszés Friss Irsai Olivér egy kiváló minőségű magyar bor, melynek illata muskotályos és citrusos, íze",
      //     index: 0,
      //     logprobs: null,
      //     finish_reason: "stop",
      //   },
      //   {
      //     text: "Az Aranymetszés Friss Irsai Olivér egy igazi magyar klasszikus.Illata muskotályos, íze gyümölcsös, és erőteljes szőlőízzel párosul. Citrusfélék",
      //     index: 1,
      //     logprobs: null,
      //     finish_reason: "length",
      //   },
      // ];
      // this.choices.selected = 0;

      let selectedRequest = this.formModel.selectedRequest;

      let API_KEY = process.env["VUE_APP_" + selectedRequest.api_key];

      let URL = selectedRequest.url;
      let prompt = this.generatePrompt();

      if (!API_KEY || !prompt) return null;

      let AI_DATA = {
        prompt: prompt,
        max_tokens: selectedRequest.max_tokens,
        model: selectedRequest.model,
        temperature: this.formModel.temperature,
        n: parseInt(this.formModel.number),
        frequency_penalty: selectedRequest.frequency_penalty,
        presence_penalty: selectedRequest.presence_penalty,
      };

      var xhr = new XMLHttpRequest();

      xhr.open("POST", URL);
      let self = this;
      xhr.onload = function () {
        if (xhr.status === 200) {
          var response = JSON.parse(xhr.responseText);
          console.log("response", response);
          if (response.choices && response.choices.length > 0) {
            self.choices.all = response.choices.map((item) => {
              item.text = item.text.slice(2);
              return item;
            });

            self.choices.all;

            self.choices.selected = 0;
          }
        }
        self.loader = false;
      };

      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.setRequestHeader("Authorization", "Bearer " + API_KEY);
      console.log("AI_DATA", AI_DATA);
      xhr.send(JSON.stringify(AI_DATA));
    },

    generatePrompt() {
      if (!this.formModel.selectedRequest[this.selectedLocale.lang]) {
        return "";
      }

      let prompt = "";
      let translated = this.formModel.selectedRequest[this.selectedLocale.lang];

      let formModel = {};

      formModel.length = this.formModel.length ?? "";
      formModel.subject = this.formModel.subject ?? "";

      formModel.keywords = "";
      if (this.formModel.keywords && this.formModel.keywords.length > 0) {
        formModel.keywords = "[" + this.formModel.keywords.toString() + "]";
      }

      formModel.source_text = this.formModel.source_text ?? "";
      formModel.number = this.formModel.number ?? "";

      this.formModel.selectedRequest.input_fields.forEach((element) => {
        if (
          formModel[element] &&
          formModel[element] != "" &&
          translated["prompt_" + element]
        ) {
          prompt += translated["prompt_" + element];
        }
      });

      prompt = prompt.replace("{length}", formModel.length);
      prompt = prompt.replace("{subject}", formModel.subject);
      prompt = prompt.replace("{keywords}", formModel.keywords);
      prompt = prompt.replace("{source_text}", formModel.source_text);
      prompt = prompt.replace("{number}", formModel.number);

      prompt = prompt.replace("{languages}", this.selectedLocale.name);
      this.generatedPrompt = prompt;
      return prompt;
    },

    handleGenerateAnswer() {
      this.$refs.AIFormValid.validate();

      if (this.AIFormValid) {
        this.AI_API_CALL();

        this.showAnswer = true;
      }
    },

    handleTokenizer() {
      this.$refs.AIFormValid.validate();

      if (this.AIFormValid) {
        const tokenizer = new GPT3Tokenizer({ type: "gpt3" });
        const str = this.generatePrompt();

        const encoded = tokenizer.encode(str);
        // const decoded = tokenizer.decode(encoded.bpe);
        // console.log(encoded);
        // console.log(decoded);
        alert(encoded.bpe.length);
      }
    },

    changedSelectedRequest(value) {
      this.generatedPrompt = null;
      this.formModel.temperature = value.temperature;
      this.formModel.number = value.number;
    },

    showInputField(fieldName) {
      if (!this.formModel || !this.formModel.selectedRequest) return false;

      if (this.formModel.selectedRequest.input_fields.includes(fieldName)) {
        return true;
      }

      return false;
    },

    copyText() {
      navigator.clipboard.writeText(
        this.choices.all[this.choices.selected].text
      );
    },

    deleteChipComboBox(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    getRemainingTokens() {
      let API_KEY = process.env["VUE_APP_OPENAI_V1_KEY"];

      let URL = "https://api.openai.com/v1/usage?date=2023-04-17";
      // let URL =
      // "https://api.openai.com/v1/usage/interval?start=2023-01-01T00:00:00Z&end=2023-05-01T23:59:59Z";

      var xhr = new XMLHttpRequest();

      xhr.open("GET", URL);

      xhr.onload = function () {
        if (xhr.status === 200) {
          var response = JSON.parse(xhr.responseText);
          let dateArray = response.data.map((item) => {
            let date = new Date(item.aggregation_timestamp * 1000);
            item.date = date.toLocaleString("hu-HU", {
              timeZone: "UTC",
            });
            return item;
          });
          console.log("response", response);
          console.log("dateArray", dateArray);
        }
      };

      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.setRequestHeader("Authorization", "Bearer " + API_KEY);
      xhr.send();
    },
  },
  mounted() {
    // this.getRemainingTokens();
  },
};
</script>

<style scoped>
.foxAI_logo {
  max-width: 9rem;
}
</style>
