<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <!-- <v-col cols="12">
            <h6>Szép kártya</h6>
            <v-text-field
              v-model="formModel.szep_card_account_number"
              label="Szép kártya"
              :disabled="formModel.id"
            ></v-text-field>
          </v-col> -->

          <v-row v-for="(block, index) in formModel.custom_fields" :key="index">
            <v-col cols="12">
              <h6>{{ block.question }}</h6>
              <v-text-field
                v-if="block.form_input == 'text'"
                :rules="requiredRules"
                v-model="block.value"
                label="Válasz"
                :disabled="disabledBlock({ ...block })"
              ></v-text-field>
              <v-text-field
                v-if="block.form_input == 'number'"
                :rules="requiredRules"
                type="number"
                min="0"
                v-model="block.value"
                label="Válasz"
                :disabled="disabledBlock({ ...block })"
              ></v-text-field>
              <v-checkbox
                v-if="block.form_input == 'checkbox'"
                v-model="block.value"
                label="Elfogad"
                :disabled="disabledBlock({ ...block })"
              ></v-checkbox>

              <v-radio-group
                v-if="block.form_input == 'radio'"
                v-model="block.value"
                :rules="requiredRules"
                inline
                :disabled="disabledBlock({ ...block })"
              >
                <v-radio label="Részesülök" :value="true"></v-radio>
                <v-radio label="Nem részesülök" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ENDPOINT,
  PERMISSION_TO,
  YEARENDPOINT,
} from "./CafeteriaStatementDatas";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

//const TRANSLATED_ATRIBUTES = [];

// export const statementData = () => ({
//   custom_fields: getCurrentYearStatement(),
// });

export const initialFormData = () => ({
  id: null,
  employee_id: 1,
  status: 1,
  custom_fields: [],
  cafeteria_statement_id: null,
});

export default {
  name: "CafeteriaStatementDataModalForm",
  props: ["modalData", "permissions", "employee"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      // statementModel: Object.assign({}, statementData),
      statementModel: {},
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,
      yearEndPoint: YEARENDPOINT,
      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) =>
          (!!v && v.length < 255) || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || v === false || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      selectedDate: null,
      statementData: null,
      currentYearStatement: null,

      // canCreate: null,
    };
  },
  computed: {
    ...mapGetters(["statuses", "currentUser"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("MENU.CAFETERIASTATEMENTDATA")
        : this.$t("MENU.NEW") + " " + this.$t("MENU.CAFETERIASTATEMENTDATA");
    },

    /*translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },*/
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });

        if (value.editedId) {
          // this.canCreate = false;
          ApiService.get(
            "cafeteria/admin/cafeteriaStatementData/" + value.editedId
          )
            .then(({ data }) => {
              this.formModel = Object.assign({}, data);
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());

          this.getCurrentYearStatement();

          //this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        // this.statementData();
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),

    getCurrentYearStatement() {
      ApiService.get(YEARENDPOINT + new Date().getFullYear())
        .then(({ data }) => {
          /*
              this.statementModel = Object.assign({}, data);
                console.log("data: ", data);
                */
          if (!this.formModel.id) {
            this.formModel.custom_fields = data.custom_fields.map((item) => {
              item.value = item.form_input == "text" ? "" : false;

              if (
                this.employee.szep_card_account_number &&
                item.initial_value_from == "szep_card_account_number"
              ) {
                item.value = this.employee.szep_card_account_number;
              }
              return item;
            });
            this.formModel.cafeteria_statement_id = data.id;

            this.formModel.employee_id = this.currentUser.employee_id;

            if (this.employee.szep_card_account_number) {
              let szepCardAccountNumberField =
                this.formModel.custom_fields.find(
                  (item) =>
                    item.initial_value_from == "szep_card_account_number"
                );

              if (szepCardAccountNumberField) {
                szepCardAccountNumberField.szep_card_account_number =
                  this.employee.szep_card_account_number;
              }
            }
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();

        this.formModel.birth_date = this.selectedDate;
        this.loader = true;
        if (!model.id) {
          //create model
          ApiService.post("cafeteria/admin/cafeteriaStatementData", model)
            .then(() => {
              // this.canCreate = null;
              // this.setCanCreate();
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    disabledBlock(block) {
      let disabled = false;

      if (
        block.disableIfRow >= 0 &&
        block.disableIfRowValue ==
          this.formModel.custom_fields[+block.disableIfRow].value
      ) {
        disabled = true;
      }
      return this.formModel.id || disabled;
    },
  },

  mounted() {
    // this.setCanCreate();
  },
};
</script>
